@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#paper{
    border: 1px solid;
    border-radius: 10px;
    padding: 10px;
    -webkit-box-shadow: 0px 0px 40px 0px rgb(0 0 0 / 40%);
    margin-top: -80px;
    z-index: 1000;
    background: #fff;
    position: relative;
    padding: 45px 0;
}

@media only screen and (max-width: 600px) {
    #paper {
      margin-top: 80px !important;
    }
   
    

  }


#fl{
      
    display: inline-block;
    vertical-align: middle;
    
    padding: 0 25px;
    font-family: Montserrat;
    
   }
   
  #fl_1{
    display: inline-block;
    vertical-align: middle;
    width: 19%;
    padding: 0 25px;
    font-family: Montserrat;
    padding-bottom: 10px;
    
  }

  #fl a{
      text-decoration: none;
      color: #fff;
  }

  #fl_1 a{
    text-decoration: none;
    color: #fff;
}

  #nav ul{
      padding: 0;
      margin: 0;
      text-align: right;
  }

  #nav ul li{
      
      display: inline-block;
      padding: 0px 30px;
  }
  #nav ul li a{
      text-decoration: none;
      line-height: 50px;
      text-transform: uppercase;
      color: rgb(176, 93, 89);
      font-weight: bold;
  }

  #icons{
    color: rgb(176, 93, 89);
  }

  #icon_nav ul{
    padding: 0;
      margin: 0;
      text-align: center;
  }

  #icon_nav ul li{
    list-style-type: none;
    display: inline-block;
    padding: 0px 5px;
  }
 
  #icon_nav ul li a{
    text-decoration: none;
    line-height: 50px;
    text-transform: uppercase;
    
}

#icon_nav ul li button{
    margin-right: 0px;
    padding: 8px 30px;
}

  #menu{
      display: none;
      margin-top: 20px;
      position: absolute;
      right: 20px;
      color: rgb(176, 93, 89);
  }

  #call{
      font-size: 14px;
      color: rgb(176, 93, 89);
      font-weight: bold;
  }

  @media only screen and (max-width: 800px) {
    #menu {
      display: block !important;
    }
    #nav ul{
        display: none;
    }
    #icon_nav ul{
        display: none;
    }
    #call{
        display: none;
    }

  }

  #drawer_style{
      display: block;
      text-align: center;
      padding: 30px 0;
      text-decoration: none;
      color: #b05d59;
  }
  #drawer_button{
        margin-left: 80px;
        
  }

  #profile_txt{
    
    
    color: black;
    line-height: 30px;
    text-align: justify;
    text-indent: 50px;
    padding-top: 25px;
    font-size: 20px;
    font-weight: 370;
  }

  #profile_heading{

  }

  #animation:hover{
    outline: 10px solid #b05d59;
    outline-offset: -10px;
    opacity: .8;
    transition: all .3s;
  }

  #s1{
    text-align: center;
    border-style: outset;
    display: inline-block;
    border-radius: 8px !important;
    
  }

  #s1:hover{
      opacity: 0.9;
      
      background-color: #b05d59;
  }

  #p_style{
    padding-top: 10px;
  }

  #carousel_titles{
    position: absolute;
    bottom: 8px;
    right: 16px;
    font-size: 24px;
    color: #fff;
    font-weight: 800;
  }
